<template>
	<div class="container">
		<div class="login-dex1">
			<ul class="c-reg-panel">
				<li>
					<div class="c-reg-panel-leftbox" style="display: flex">
						<!-- <country-code-selector :countryCode.sync="phoneCountry" style="padding: 0;padding-right: 10px;color: #333333;width: 145px;"></country-code-selector> -->
						<input type="text" @input="checkPhone" v-model="inputValue" :placeholder="$t('login.PleaseEnterYourMobilePhoneNumber')" />
					</div>
					<div class="c-reg-panel-rightbox">
						<img :src="rigthImg" v-show="!errorInputShow && userInput" />
						<img :src="errorImg" v-show="errorInputShow" />
					</div>
				</li>
				<li>
					<div class="c-reg-panel-leftbox posRelat">
						<van-row>
							<!-- <van-col>
								<img
									style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
									src="@/assets/imgs/login/icon_verificationcode.png"
									alt=""
								/>
							</van-col> -->
							<van-col span="20">
								<input type="text" @input="checkCode" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
								<div class="getCodeBtn">
									<button @click="onSendCode" :disabled="codeSending">
										<span v-if="codeSending">({{ theTime }})</span>
										<span v-else>{{ $t('sPhone.getCode') }}</span>
									</button>
								</div>
							</van-col>
						</van-row>
					</div>
				</li>
			</ul>
		</div>
		
		<!-- [验证弹出图层-滑块区域 start] -->
		<!-- <div class="puzzle-box">
			<PuzzleVerification v-model="isVerificationShow1" blockType="puzzle" :puzzleImgList="puzzleImgList" :onSuccess="handleSuccess" />
		</div> -->
		<!-- [验证弹出图层-滑块区域 end] -->

		<!-- <div class="wjmm">
			<span @click="routerToZhuCe()">{{ $t('login.RegisteredAccountNumber') }}</span>
		</div> -->
		
		<button class="loginButtonQ" :class="haveCompleted ? 'mainBackColor1' : 'disabled'" :disabled="!haveCompleted" @click="doLogin" style="margin-bottom: 0.4rem;">{{ $t('login.login') }}</button>
		
	</div>
</template>
<script>
import PuzzleVerification from 'vue-puzzle-verification';
import countryCodeSelector from 'vue-country-code-selector';
export default {
	name: 'loginPhone',
	data() {
		return {
			puzzleImgList: [],
			isVerificationShow1: false,
			info: '',
			rigthImg: require('@/assets/imgs/login/login-right.png'),
			errorImg: require('@/assets/imgs/login/login-error.png'),
			inputValue: '',
			inputCode: '',
			haveValue: false,
			haveCode: false,
			sid: '', //session id
			nation: '66', //country code
			userInput: false,
			errorInputShow: false,
			codeSending: false,
			theTime: 0,
			ua: false,
			isWeixin: false,
			isNabox: false,
			address: '',
			phoneCountry: 86,
			// ----20210413[start]----
			haveCompleted: false,
			havePhone: false,
			hasSending: false,
		};
	},
	components: {
		countryCodeSelector,
		PuzzleVerification
	},
	created() {
		// this.refcode = this.$route.query.refcode;
		this.isWeixinFlag();
		this.isNaboxFlag();
		this.$post2('User/api/passport/loginNotice')
			.then(res => {
				console.log(res);
				this.info = res;
			})
			.catch(err => {
				console.log(err);
			});
	},
	beforeDestroy() {
		// 短信计时器
		if (this.codeTimer) {
			clearTimeout(this.codeTimer);
		}
	},
	methods: {
		// 前往注册页面
		routerToZhuCe() {
			this.$router.push('/registerPage');
		},
		// 自定义弹出框
		showAlert(msg) {
			return this.$dialog.alert({
				title: '提示',
				message: msg
			});
		},
		// 手机号是否为空
		checkPhone(e) {
			let v = e.target.value;
			if (v.length) {
				this.errorInputShow = false;
				this.havePhone = true
			} else {
				this.errorInputShow = true;
				this.havePhone = false
			}
		},
		// isPhoneNumber(phone) {
		// 	if (!/^1[3456789]\d{9}$/.test(phone)) {
		// 		return false;
		// 	}
		// 	return true;
		// },
		// 登录按钮是否可用--1、正确的手机号；2、发送过验证码；3、验证码输入框不为空
		checkCode() {
			if (this.inputCode != '' && this.havePhone) {
				this.haveCompleted = true;
			} else {
				this.haveCompleted = false;
			}
		},
		
		// [以下代码未知]----------
		selectCountry(event) {
			let country_tel = event;
			//向手机登录页面传参
			// this 。$ router 。推（{ 名称：“phoneLogin” ，PARAMS：{ 电话：country_tel } } ）
		},
		login() {
			window.location =
				'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3482eac80cc986cc&redirect_uri=https://tpu.yqkkn.com/wx/index/info&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';
		},
		
		// 验证码滑块区域
		// handleSuccess() {
		// 	this.isVerificationShow1 = false;
		// 	this.theTime = 60;
		// 	this.codeSending = true;
		// 	this.doCountDown();
		// 	this.$post2(global.domain.domain + '/security/index/phonecode', {
		// 		phone: this.inputValue,
		// 		nation: this.phoneCountry,
		// 		op: 'register'
		// 	}).then(res => {
		// 		// console.log(res);
		// 		this.sid = res.sid;
		// 	});
		// },
		isWeixinFlag() {
			var ua = navigator.userAgent.toLowerCase();
			this.isWeixin = ua.match(/MicroMessenger/i) == 'micromessenger';
		},
		isNaboxFlag() {
			if (typeof window.NaboxJSBridge == 'undefined') {
				this.isNabox = false;
			} else {
				this.isNabox = true;
			}
		},
		
		naboxView() {
			this.showAlert(localStorage.getItem('vv'));
		},
		naboxLogin() {
			var that = this;
			window.NaboxJSBridge.invoke(
				'authorization', //调用方法
				//业务数据
				null,
				function(data) {
					var obj = JSON.parse(data);
					localStorage.setItem('vv', data);
					if (obj.requestCode == 'ok') {
						that.$post2('user/api/passport/loginWithPubkey', {
							address: obj.address,
							pubKey: obj.pubKey,
							sign: obj.sign,
							blockHeight: obj.blockHeight,
							token: 'NULS'
						})
							.then(res => {
								that.$saveLoginSession(res);
								// that.$router.push("/dig");
								that.$router.push({
									path: '/newHome',
									query: {
										ifhave: false
									}
								});
							})
							.catch(e => {
								that.showAlert(e);
							});
					}
				}
			);
		},
		// [以上代码未知]--------
		
		// 发送短信验证码
		onSendCode() {
			console.log(this.phoneCountry);
			// if (!this.isPhoneNumber(this.inputValue)) {
			//   // this.showAlert("请输入正确的手机号");
			//   this.$toast(this.$t('login.PleaseEnterRightPhoneNumber'));
			//   return false;
			// }
			
			if (this.codeSending) {
				return;
			}
			// this.isVerificationShow1 = true;
			// return
			
			// this.haveValue = false;
			this.haveCompleted = false;
			
			this.hasSending = true
			

			this.theTime = 60;
			this.codeSending = true;
			this.doCountDown();
			this.$post2(global.domain.domain + '/security/index/phonecode', {
				phone: this.inputValue,
				nation: this.phoneCountry,
				op: 'login'
			}).then(res => {
				// console.log(res);
				this.sid = res.sid;
			});
		},
		// 短信验证码倒计时
		doCountDown() {
			if (this.theTime > 1) {
				this.theTime--;
				this.codeTimer = setTimeout(() => {
					this.doCountDown();
				}, 1000);
			} else {
				this.codeSending = false;
				this.haveValue = true;
			}
		},
		
		
		// 驗證碼登陸
		doLogin() {
			let toast=this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: this.$t('system.Loading'),
			});
			this.$post2('user/api/passport/loginWithCode', {
				phone: this.inputValue,
				sid: this.sid,
				code: this.inputCode,
				refcode: this.refcode,
				nation: this.phoneCountry
			})
				.then(res => {
					toast.clear();
					// console.log(res);
					// this.$toast("登录成功");
					this.$saveLoginSession(res);
					// this.showAlert(localStorage.phone);
					
					// 获取策略菜单
					this.$store.dispatch('appInit')

					this.$router.push({
						path: '/newHome',
						query: {
							ifhave: false
						}
					});
				})
				.catch(e => {
					toast.clear();
					// this.showAlert(e);
					// this.$toast.fail(No_register)
					if(e == 'not register'){
						this.$toast.fail(this.$t('login.No_register'))
					}else {
						this.$toast.fail(this.$t('login.login_failed'));
					}
				});
		}
	}
};
</script>
<style>
.puzzle-box {
	position: absolute;
	z-index: 99;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.login-dex1 {
	margin-top: 0.55rem;
}
.longtitle {
	font-size: 0.25rem;
	color: black;
	width: 5.6rem;
	font-weight: bold;
	text-align: center;
	/* background: #1e1010; */
	margin: 1.4rem auto;
	/* padding: 0.4rem 0.4rem 0; */
}
.logintitleInfo {
	padding: 1rem 0 0 0;
	width: 6.06rem;
	/* height:0.9rem; */
	/* line-height: 0.9rem; */
	/* background:#eee; */
	opacity: 0.9;
	border-radius: 0.2rem;

	color: #fff;
	margin: 0 auto;
	/* text-align: center; */
}
.logintitleInfo .p1 {
	font-size: 0.3rem;
}
.logintitleInfo .p2 {
	line-height: 0.4rem;
	color: #ccc;
	font-size: 0.25rem;
	text-indent: 2em;
}
</style>
